import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { get, isEmpty } from "lodash";
import Loader from "../../components/Loader";
import FrontPageSearchBox from "../../components/FrontPageSearchBox";
import Locations from "./ContentViews/locations";
import { getLocation } from "../../locationHelpers";
import * as locationActions from "../../actions/locations";
import * as widgetActions from "../../actions/widgets";
import styled from "styled-components";

class SearchLocations extends Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.history.location.search);

    this.state = {
      search: params.get("search") ?? "",
      displayType: params.get("type"),
      dataSource: params.get("show") ?? "avg",
      queryHash: null,
      locationQueries: [],
      hasLoadedView: false,
    };

    this.storeQueryParams = this.storeQueryParams.bind(this);
    this.performSearch = this.performSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
    this.onRunQuery = this.onRunQuery.bind(this);
    this.onDisplayTypeChanged = this.onDisplayTypeChanged.bind(this);
    this.onDataSourceChanged = this.onDataSourceChanged.bind(this);

    this.storeQueryParams();
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Search`;

    // Set moment locale every time the component mounts
    moment.locale("en");

    // Easter egg
    if (this.state.search === "rickroll") {
      window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
      return;
    }

    // console.log("SearchLocations.componentDidMount", this.props);
    if (!isEmpty(this.props.customTags) && !isEmpty(this.props.locationHierarchy)) {
      this.onRunQuery();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    
    const loadingQuery = this.props.loadingQueries[this.state.queryHash];
    const oldLoadingQuery = prevProps.loadingQueries[this.state.queryHash];

    // console.log("SearchLocations.componentDidUpdate", this.props);

    if (!this.state.hasLoadedView) {
      if (!isEmpty(this.props.customTags) && !isEmpty(this.props.locationHierarchy)) {
        this.onRunQuery();
      }

      return;
    }

    const queryChanged = loadingQuery === "completed" && oldLoadingQuery !== "completed";
    if (queryChanged || this.state.displayType !== prevState.displayType) {
      this.fetchData();
    }
  }

  storeQueryParams() {
    const queryParams = {};
    const params = new URLSearchParams();

    if (!isEmpty(this.state.search)) {
      queryParams.search = this.state.search;
      params.set("search", this.state.search);
    }

    if (!isEmpty(this.state.displayType)) {
      queryParams.type = this.state.displayType;
      params.set("type", this.state.displayType);
    }

    params.set("show", this.state.dataSource);

    this.props.setLocationSearch(queryParams);

    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  performSearch() {

    // Easter egg
    if (this.state.search === "rickroll") {
      window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
      return;
    }

    this.onRunQuery();
    this.storeQueryParams();
  }

  onDisplayTypeChanged(type) {
    this.setState({ displayType: type, offset: 0 }, this.storeQueryParams);
  }

  onDataSourceChanged(dataSource) {
    this.setState({ dataSource }, this.storeQueryParams);
  }

  onSearchChange(text) {
    this.setState({ search: text, offset: 0 });
  }

  onSearchClear() {
    this.setState({ search: "", offset: 0 }, this.performSearch);
  }

  onRemoveTagClick(tag) {
    if (tag.id === "???") {
      this.performSearch();
    }
  }

  onRunQuery() {
    // console.log("SearchLocations.onRunQuery");

    const startDate = moment().subtract(1, "month").startOf("month");
    const endDate = moment().subtract(1, "month").endOf("month");
    const queryHash = widgetActions.homeQueryHash(this.state.search, startDate, endDate);

    const topLocationIds = this.props.locationHierarchy.map(l => l.id);

    const topLocationQueries = topLocationIds.map(id => ({ property: "_id", value: id, operator: "eq", options: { includeDescendants: true } }));
    const query = {
      or: [
        ...topLocationQueries.map(topLocationQuery => ({ and: [topLocationQuery, { property: "type", value: `.*${this.state.search}.*`, operator: "regex" }] })),
        ...topLocationQueries.map(topLocationQuery => ({ and: [topLocationQuery, { property: "name", value: `.*${this.state.search}.*`, operator: "regex" }] }))
      ]
    };

    this.setState({ queryHash, hasLoadedView: true });

    // If we already have the data, skip the query
    if (this.props.loadingQueries[queryHash] === "completed") {
      this.fetchData();
    }
    else if (!this.props.loadingQueries[queryHash]) {
      this.props.newQueryLocations(query, queryHash);
    }

  }

  onSearchKeyPress(event) {
    if (event.key && event.key === "Enter") {
      event.preventDefault();
      this.performSearch();
    }
  }

  fetchData() {
    console.log("SearchLocations.fetchData");
    const locations = this.props.completedQueries[this.state.queryHash]?.locations ?? [];
    const safeLocationIds = locations.filter(location => location.type != "asset.workstation").map(location => location._id);

    if (safeLocationIds.length === 0) {
      return
    }

    // Get locations queries
    if (this.state.displayType === "occupancy" || this.state.displayType === "lowcount" || this.state.displayType === "highcount") {
      this.props.getLocationsOccupancyWeekdays(safeLocationIds);
    }
    else if (this.state.displayType === "people") {
      this.props.getLocationsMaxPeopleWeekdays(safeLocationIds);
    }
  }

  render() {
    const { isLoading } = this.props;
    // console.log("Home.SearchLocations.state", this.state);
    // console.log("Home.SearchLocations.props", this.props);

    if (isLoading) {
      return <Loader fullScreen />;
    }

    let customTags = [];

    const searchBox = (
      <FrontPageSearchBox
        query={{ customTags, text: this.state.search, placeholder: "What are you looking for?" }}
        onQueryChange={this.onSearchChange}
        onSearchClick={this.performSearch}
        onKeyDown={this.onSearchKeyPress}
        onClear={this.onSearchClear}
        onRemoveTagClick={this.onRemoveTagClick}
      />
    );

    const heading = (
      <div style={{ marginLeft: "auto", marginRight: "auto", flexGrow: 1, maxWidth: "800px", marginTop: "60px", marginBottom: "60px" }}>
        {searchBox}
      </div>
    );

    return (
      <OuterContainer>
        <InnerContainer>
          {heading}
          <ContentContainer>
            <Locations
              {...this.props}
              queryHash={this.state.queryHash}
              displayType={this.state.displayType ?? "all"}
              onDisplayTypeChanged={this.onDisplayTypeChanged}
              dataSource={this.state.dataSource}
              onDataSourceChanged={this.onDataSourceChanged}
            />
          </ContentContainer>
        </InnerContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationHierarchy: state.locations.hierarchy,
    customTags: state.customTags.list,
    loadingQueries: state.locationQuery.loadingQueries,
    completedQueries: state.locationQuery.completedQueries,
    dashboardData: state.dashboards.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLocationsOccupancyWeekdays: widgetActions.getLocationsOccupancyWeekdays,
    getLocationsMaxPeopleWeekdays: widgetActions.getLocationsMaxPeopleWeekdays,
    newQueryLocations: locationActions.newQueryLocations,
    setLocationSearch: locationActions.setLocationSearch,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchLocations);

const OuterContainer = styled.div`
  position: relative;
  display: block;
  height: calc(100vh - 115px);
  width: 100%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }

  p, ol {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  width: ${props => props.$showSideBar ? "calc(100% - 345px)" : "100%"};
  max-height: 100%;
  height: 100%;
  overflow: auto;

  padding-left: ${props => props.$mobile ? "20px" : "40px"};
  padding-right: ${props => props.$mobile ? "20px" : "40px"};
  padding-bottom: 40px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;