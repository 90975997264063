import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import get from "lodash/get";
import styled from "styled-components";
import { lighten, desaturate, darken } from "polished";
import { colorGradientUptime } from "../../../helpers";
import { Popup, PopupAnchor, SourceAnchor } from "ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import * as selectedActions from "../../../actions/selected";
import * as widgetActions from "../../../actions/widgets";

class SmallUptimeView extends Component {

  constructor(props) {
    // console.log("SmallUptimeView.constructor");
    super(props);
    this.state = {
      hoverUptimeIndex: null,
      hoverUptimeValue: 0,
      hoverUptimeRect: null
    };
    this.handleUptimeMouseMove = this.handleUptimeMouseMove.bind(this);
    this.handleUptimeMouseLeave = this.handleUptimeMouseLeave.bind(this);

    const widgetKey = `${this.props.topLocation.id}-${this.props.widget.wpaCategoryId ?? null}-${this.props.widget.customTagId ?? null}`; // use null instead of undefined
    const sourceData = get(this.props.widgetData, `[${widgetKey}].uptime`, null);

    if (sourceData === null) {
      this.props.getUptime(this.props.topLocation.id, this.props.widget.wpaCategoryId, this.props.widget.customTagId);
    }
  }

  // Update mouse position for hover text
  handleUptimeMouseMove(event, index, hoverUptimeValue, hoverLocationId) {
    const rect = event.target.getBoundingClientRect();
    this.setState({ hoverUptimeIndex: index, hoverUptimeValue, hoverUptimeRect: rect });
  }

  // Clear mouse position when mouse leaves the area, to hide hover text
  handleUptimeMouseLeave() {
    this.setState({ hoverUptimeIndex: null, hoverUptimeValue: null, hoverUptimeRect: null });
  }

  stopEvent(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  render() {
    // console.log("SmallUptimeView.state", this.state);
    console.log("SmallUptimeView.props", this.props);

    const widgetKey = `${this.props.topLocation.id}-${this.props.widget.wpaCategoryId ?? null}-${this.props.widget.customTagId ?? null}`; // use null instead of undefined
    const sourceData = get(this.props.widgetData, `[${widgetKey}].uptime`, {});
    const uptimeDays = get(sourceData, "days", []);
    const sumUptime = Math.floor(get(sourceData, "totalUptime", 0) * 1000)/10;
    const loading = get(this.props.widgetLoading, `[${widgetKey}].uptime`, false);

    const firstOfMonth = moment().subtract(1, "month").startOf("month");
    const monthString = firstOfMonth.format("MMMM");
    const firstOfMonthString = firstOfMonth.format("D. MMM");
    const endOfMonth = moment().subtract(1, "month").endOf("month");
    const endOfMonthString = endOfMonth.format("D. MMM");

    // Get uptime data for the month
    const days = Array.from({ length: endOfMonth.date() }, (_, i) => {
      const day = firstOfMonth.clone().add(i, "days");
      return uptimeDays.find(uptime => moment(uptime.datetime).isSame(day, "day")) ?? { uptime: undefined, datetime: day.toISOString() };
    });
    
    let footerDescription = <UptimeFooterText>No data</UptimeFooterText>;
    if (loading) {
      footerDescription = <UptimeFooterText>Loading...</UptimeFooterText>;
    }
    else if (uptimeDays.length > 0) {
      footerDescription = <UptimeFooterText>{sumUptime}% uptime</UptimeFooterText>;
    }

    const uptimeElement = (
      <>
        <UptimeDataRow onMouseLeave={this.handleUptimeMouseLeave}>
          {
            days.map((day, i) => {
              let backgroundColor;

              // Get colors for uptime
              if (day.uptime === undefined) {
                backgroundColor = "#DDD";
              }
              else {
                let scaledValue = day.uptime;
                if (day.uptime <= 0.3) {
                  scaledValue = 0;
                }
                else {
                  scaledValue = (day.uptime - 0.3)/0.7;
                }

                const gradientValue = 1 - scaledValue;
                backgroundColor = colorGradientUptime(gradientValue); 
              }

              return (
                <UptimeBlock 
                  key={i}
                  style={{ backgroundColor }}
                  // onClick={(event) => this.onSelectedUptimeIndexChange(event, i)}
                  onMouseMove={(event) => this.handleUptimeMouseMove(event, i, day.uptime, null)}
                />
              );
            })
          }
        </UptimeDataRow>
        <UptimeFooter>
          <UptimeFooterText>{firstOfMonthString}</UptimeFooterText>
          <Spacer />
          { footerDescription }
          <Spacer />
          <UptimeFooterText>{endOfMonthString}</UptimeFooterText>
        </UptimeFooter>
      </>
    );

    const title = "Sensor uptime";
    const description = `An overview of sensor uptime for ${monthString}, shown as colored blocks: green means all sensors contributed data, yellow indicates some downtime, and red signals significant downtime.`;

    let popupElement = null;
    // Show popup if hovering over a uptime value
    if (this.state.hoverUptimeRect && this.state.hoverUptimeIndex !== null) {
      const day = days[this.state.hoverUptimeIndex];
      const dateString = moment(day.datetime).format("D. MMMM");
      const uptimeString = day.uptime === undefined ? "No data" : `${Math.floor(day.uptime * 1000)/10}% uptime`;
      popupElement = (
        <Popup
          isVisible={!!this.state.hoverUptimeRect}
          sourceRect={this.state.hoverUptimeRect}
          popupAnchor={PopupAnchor.TOP_CENTER}
          sourceAnchor={SourceAnchor.EDGE}
          showArrow={false}
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            borderWidth: "0px",
            borderStyle: "none",
            width: "115px",
            zIndex: 1000,
            boxShadow: "none",
            pointerEvents: "none"
          }}
        >
          <PopupBox>
            <div style={{ fontSize: "14px", fontWeight: 800, marginBottom: "2px" }}>{dateString}</div>
            <div style={{ fontSize: "14px", fontWeight: 400, color: "#555" }}>{uptimeString}</div>
          </PopupBox>
        </Popup>
      );
    }

    return (
      <Container onClick={this.props.onClose}>
        <ScrollBox>
          <Block onClick={this.stopEvent}>
            <CloseButton onClick={this.props.onClose}>
              <FontAwesomeIcon icon={faXmark} size="lg" />
            </CloseButton>
            <BlockContent>
              <BlockInnerTitle>{title}</BlockInnerTitle>
              <BlockInnerDescription>{description}</BlockInnerDescription>
              <UptimeColumn>
                {uptimeElement}
              </UptimeColumn>
              {popupElement}
            </BlockContent>
          </Block>
        </ScrollBox>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    widgetData: state.widgets.data,
    widgetLoading: state.widgets.loading,
    locationHierarchy: state.locationQuery.hierarchy,
    flatHierarchy: state.locationQuery.flatHierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    isLoadingQuery: state.locationQuery.isLoadingQuery
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUptime: widgetActions.getUptime,
    selectLocation: selectedActions.selectLocation,
    selectLocations: selectedActions.selectLocations,
    deselectLocation: selectedActions.deselectLocation,
    deselectAllLocations: selectedActions.deselectAllLocations,
    clearSelection: selectedActions.clearSelection,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SmallUptimeView);

const BlockInnerTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
`;

const BlockInnerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const BlockInnerDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  margin-bottom: 5px;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

const ScrollBox = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  // Center content horizontally
  display: flex;
  justify-content: center;
  align-items: flex-start;

  // Center content vertically if screen height is large
  @media (min-height: 600px) {
    align-items: center;
  }
`;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  max-width: 600px;
`;

const BlockContent = styled.div`
  position: relative;
  // min-height: 300px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 3;

  font-size: 20px;
  font-weight: 100;
  color: #333;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const UptimeColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 8px;
  width: 100%;
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const UptimeDataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 3px;
  width: 100%;
  flex-grow: 1;
  cursor: pointer;
`;

const UptimeBlock = styled.div`
  flex-grow: 1;
  height: 40px;
`;

const UptimeFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #888;
`;

const Spacer = styled.div`
  height: 1px;
  flex-grow: 1;
  background-color: #ddd;
  margin-left: 10px;
  margin-right: 10px;
`;

const UptimeFooterText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #888;
`;

const PopupBox = styled.div`
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #ccc; /* Box border */
  border-radius: 5px;
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  pointer-events: none;
  margin-top: 8px;

  /* Arrow on top */
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -9px; /* Position the arrow above the popup */
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
  }

  /* Arrow border (larger arrow behind) */
  &::before {
    border-width: 0 10px 9px 10px; /* Arrow pointing upwards */
    border-color: transparent transparent #ccc transparent; /* Border color */
    z-index: 0; /* Behind the arrow */
  }

  /* Arrow (foreground) */
  &::after {
    margin-top: 1px; /* Adjust to align with the box border */
    border-width: 0 9px 8px 9px; /* Slightly smaller to create border effect */
    border-color: transparent transparent #FFFFFF transparent; /* Box background color */
    z-index: 1; /* In front of the border */
  }
`;