import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import moment from "moment";
import { get, isEmpty } from "lodash";
import WidgetGrid from "./ContentViews/widgetGrid";
import HomeSideBar from "./LayoutControllers/homeSideBar";
import HomeHeader from "./LayoutControllers/homeHeader";
import SourceView from "./ContentViews/sourceView";
import SmallUptimeView from "./ContentViews/smallUptimeView";
import Loader from "../../components/Loader";
import { getCompanyId } from "../../helpers";
import { getWidgets, WPAColors, WPAColorsDark } from "./helpers";
import * as widgetActions from "../../actions/widgets";
import * as reportActions from "../../actions/reports";

class WidgetsPage extends Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.history.location.search);
    const companyId = getCompanyId();
    const favoriteBuildingId = localStorage.getItem(`df_${companyId}`);

    this.state = {
      regions: [],
      buildings: [],
      selectedBuildingId: params.get("b") ?? favoriteBuildingId ?? "",
      favBuildingId: favoriteBuildingId,
      locationHierarchy: null,
      selectedPageId: params.get("p") ?? "overview",
      hasLoadedView: false,
      pages: [],
      showSourcesForWidget: null,
      showDataQualityForWidget: null
    };

    this.storeQueryParams = this.storeQueryParams.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onBuildingClick = this.onBuildingClick.bind(this);
    this.onToggleFavorite = this.onToggleFavorite.bind(this);
    this.onSelectedPageChanged = this.onSelectedPageChanged.bind(this);
    this.onShowSourcesForWidget = this.onShowSourcesForWidget.bind(this);
    this.onShowDataQualityForWidget = this.onShowDataQualityForWidget.bind(this);

    if (isEmpty(this.props.wpaCategories)) {
      this.props.getWpaCategories();
    }

    if (this.props.widgetProfile === null) {
      this.props.getWidgetProfile(this.props.match.params.companyId);
    }

    this.storeQueryParams();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("Home.FrontPage.getDerivedStateFromProps", nextProps, prevState);

    // Run once when we get location hierarchy and wpa categories
    if (!prevState.hasLoadedView && !isEmpty(nextProps.locationHierarchy) && !isEmpty(nextProps.wpaCategories) && nextProps.widgetProfile !== null) {

      // Get all locations of type "building"
      let newHierarchy = JSON.parse(JSON.stringify(nextProps.locationHierarchy));

      let topLocation = { id: "*", children: newHierarchy, name: "" };

      // Get all location from topLocation.children with type building
      const regions = [];
      const buildings = [];
      const getLocations = (location, parentRegion) => {

        // If location is a building, add it to the list
        if (location.id !== "*" && location.type === "building") {
          const region = parentRegion ? regions.find(region => region.id === parentRegion.id) : null;

          const widgets = [];

          if (region) {
            region.children.push({ ...location, widgets });
          }
          else {
            regions.push({ id: parentRegion.id, name: parentRegion.name, capacity: parentRegion.capacity, squareMeters: parentRegion.squareMeters, children: [{ ...location, widgets }] });
          }

          buildings.push({ ...location, widgets });
        }

        if (location.children) {
          let newParentRegion = parentRegion;
          if (location.type === "region") {
            newParentRegion = location;
          }
          location.children.forEach(child => getLocations(child, newParentRegion));
        }
      }

      getLocations(topLocation, { id: "*", name: "No region" });

      // Sort regions by name (but "*" last) and buildings by name
      regions.sort((a, b) => a.id === "*" ? 1 : b.id === "*" ? -1 : a.name.localeCompare(b.name, "nb-NO"));
      regions.forEach(region => region.children.sort((a, b) => a.name.localeCompare(b.name, "nb-NO")));

      // Add all buildings as the first child of the region
      regions.forEach(region => {
        if (region.children.length > 1) {

          const widgets = [];

          if (region.id !== "*") {
            region.children.unshift({ id: region.id, name: "All buildings", type: "region", capacity: region.capacity, squareMeters: region.squareMeters, widgets });
          }
        }
      });

      // Create pages
      const pages = [{ id: "overview", name: "Overview", color: WPAColors.OVERVIEW, darkColor: WPAColorsDark.OVERVIEW }];
      nextProps.wpaCategories.forEach(category => {
        // Get the color based on the category name
        let name = category.name;
        let color = WPAColors.OVERVIEW;
        let darkColor = WPAColorsDark.OVERVIEW;
        let resourceName = "rooms";
        let singleResourceName = "room";
        if (category.isCollaborationSpace) {
          name = "Collaboration spaces";
          color = WPAColors.COLLABORATION_ROOMS;
          darkColor = WPAColorsDark.COLLABORATION_ROOMS;
        }
        else if (category.isSupportingSpace) {
          name = "Supporting spaces";
          color = WPAColors.SUPPORTING_ROOMS;
          darkColor = WPAColorsDark.SUPPORTING_ROOMS;
        }
        else if (category.isActivityZone) {
          name = "Activity zones";
          color = WPAColors.ACTIVITY_ZONE;
          darkColor = WPAColorsDark.ACTIVITY_ZONE;
          resourceName = "zones";
          singleResourceName = "zone";
        }
        else if (category.isWorkstations) {
          name = "Workstations";
          color = WPAColors.WORKSTATIONS;
          darkColor = WPAColorsDark.WORKSTATIONS;
          resourceName = "workstations";
          singleResourceName = "workstation";
        }

        pages.push({
          id: category._id,
          wpaCategoryId: category._id,
          name,
          color,
          darkColor,
          resourceName,
          singleResourceName
        });
      });

      // If any custom tags are defined, add a page for people count
      if (nextProps.widgetProfile?.defaultPeopleCountCustomTagId || (nextProps.widgetProfile?.locationPeopleCountCustomTags && nextProps.widgetProfile?.locationPeopleCountCustomTags.length > 0)) {
        pages.push({ id: "peopleCount", name: "People count", color: WPAColors.PEOPLE_COUNT, darkColor: WPAColorsDark.PEOPLE_COUNT, resourceName: "locations", singleResourceName: "location" });
      }

      // Select first building in first region, if no building is selected
      if (!prevState.selectedBuildingId) {
        const firstRegion = regions.find(region => region.children.length > 0);
        if (firstRegion) {
          const firstBuilding = firstRegion.children[0];
          if (firstBuilding) {
            return { regions, buildings, locationHierarchy: nextProps.locationHierarchy, selectedBuildingId: firstBuilding.id, pages, hasLoadedView: true };
          }
        }
      }

      return { regions, buildings, locationHierarchy: nextProps.locationHierarchy, pages, hasLoadedView: true };
    }

    return null;
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Home`;
    // console.log("Home.FrontPage.componentDidMount", this.state, this.props);

    // Set moment locale every time the component mounts
    moment.locale("en");

    if (this.state.selectedBuildingId && this.props.widgetProfile && this.props.wpaCategories && this.props.locationHierarchy) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("Home.FrontPage.componentDidUpdate", this.state, prevState);
    // console.log("Home.FrontPage.componentDidUpdate", this.props);

    const buildingChanged = this.state.selectedBuildingId && this.state.selectedBuildingId !== prevState.selectedBuildingId;
    const pageChanged = this.state.selectedPageId !== prevState.selectedPageId;

    if (this.state.hasLoadedView) {
      // If first load or building or wpa category has changed
      if (!prevState.hasLoadedView) {
        this.fetchData();
      }
      else if (this.props.widgetProfile) {
        if (buildingChanged || pageChanged) {
          this.fetchData();
        }
      }
    }
  }

  storeQueryParams() {
    const queryParams = {};
    const params = new URLSearchParams();

    if (!isEmpty(this.state.selectedBuildingId)) {
      queryParams.b = this.state.selectedBuildingId;
      params.set("b", this.state.selectedBuildingId);
    }

    if (!isEmpty(this.state.selectedPageId)) {
      queryParams.p = this.state.selectedPageId;
      params.set("p", this.state.selectedPageId);
    }

    this.props.setWidgetSearch(queryParams);

    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  fetchData() {
    const widgetQueries = [];

    // Get overview queries
    if (this.state.selectedPageId === "overview") {
      const overviewWidgets = get(this.props.widgetProfile, "overviewWidgets", []);
      overviewWidgets.forEach(widget => {
        const buildingId = this.state.selectedBuildingId;
        const wpaCategoryId = widget.wpaCategoryId ?? null;
        const customTagId = widget.customTagId ?? null;
        const widgetKey = `${buildingId}-${wpaCategoryId}-${customTagId}`;
        if (!get(this.props.widgetData, `${widgetKey}.${widget.source}`, false)) {
          widgetQueries.push({ id: widget.source, buildingId: this.state.selectedBuildingId, wpaCategoryId, customTagId });
        }
      });
    }
    // Get people count queries
    else if (this.state.selectedPageId === "peopleCount") {
      // Check if we have the data for the selected building
      const locationPeopleCountCustomTags = get(this.props.widgetProfile, "locationPeopleCountCustomTags", []);
      let customTagId = locationPeopleCountCustomTags.find(tag => tag.locationId === this.state.selectedBuildingId)?.customTagId;
      if (!customTagId) {
        customTagId = this.props.widgetProfile?.defaultPeopleCountCustomTagId;
      }

      if (customTagId) {
        const widgetKey = `${this.state.selectedBuildingId}-${null}-${customTagId}`;
        const dataTypes = ["peopleCountIntraday", "peopleCountWeekday", "peopleCountMonth", "atCapacityMinutes"];
        dataTypes.forEach(dataType => {
          if (!get(this.props.widgetData, `${widgetKey}.${dataType}`, false)) {
            widgetQueries.push({ id: dataType, buildingId: this.state.selectedBuildingId, wpaCategoryId: null, customTagId });
          }
        });
      }
    }
    // Get workplace category queries
    else {

      // Find the workplace category
      const wpaCategory = this.props.wpaCategories.find(category => category._id === this.state.selectedPageId);
      if (wpaCategory) {
        // Check if we have the data for the selected building
        const widgetKey = `${this.state.selectedBuildingId}-${wpaCategory._id}-${null}`;

        // Ignored widgets: "neverUsedResourcesCount" and "utilizationIntraday"

        const dataTypes = ["fullyUtilizedMinutes", "notUtilizedMinutes", "utilizationDay", "utilizationWeekday", "utilizationMonth", "utilizationDistributionMonth", "utilizationMonthTopLocations", "utilizationMonthBottomLocations"];
        dataTypes.forEach(dataType => {
          if (!get(this.props.widgetData, `${widgetKey}.${dataType}`, false)) {
            widgetQueries.push({ id: dataType, buildingId: this.state.selectedBuildingId, wpaCategoryId: wpaCategory._id, customTagId: null });
          }
        });
      }
    }

    // Remove duplicate widget queries
    const uniqueQueries = widgetQueries.filter((query, index, self) =>
      index === self.findIndex(t => (
        t.id === query.id && t.buildingId === query.buildingId && t.wpaCategoryId === query.wpaCategoryId && t.customTagId === query.customTagId
      ))
    );

    if (uniqueQueries.length > 0) {
      uniqueQueries.forEach(query => {
        switch (query.id) {
          case "peopleCountIntraday":
            this.props.getPeopleCountIntraday(query.buildingId, query.customTagId);
            break;
          case "peopleCountWeekday":
            this.props.getPeopleCountWeekday(query.buildingId, query.customTagId);
            break;
          case "peopleCountMonth":
            this.props.getPeopleCountMonth(query.buildingId, query.customTagId);
            break;
          case "atCapacityMinutes":
            this.props.getAtCapacityMinutes(query.buildingId, query.customTagId);
            break;
          case "fullyUtilizedMinutes":
            this.props.getFullyUtilizedMinutes(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "notUtilizedMinutes":
            this.props.getNotUtilizedMinutes(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "neverUsedResourcesCount":
            this.props.getNeverUtilized(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationIntraday":
            this.props.getUtilizationIntraday(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationDay":
            this.props.getUtilizationDay(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationWeekday":
            this.props.getUtilizationWeekday(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationMonth":
            this.props.getUtilizationMonth(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationDistributionMonth":
            this.props.getUtilizationMonthlyDistribution(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationMonthTopLocations":
            this.props.getUtilizationTopLocations(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationMonthBottomLocations":
            this.props.getUtilizationBottomLocations(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          default:
            break;
        }
      });
    }
  }

  onBuildingClick(locationId) {
    this.setState({ selectedBuildingId: locationId }, this.storeQueryParams);
  }

  onToggleFavorite(locationId) {
    this.setState({ favBuildingId: locationId });
    const companyId = getCompanyId();
    localStorage.setItem(`df_${companyId}`, locationId);
  }

  onSelectedPageChanged(id) {
    this.setState({ selectedPageId: id }, this.storeQueryParams);
  }

  onShowSourcesForWidget(widget) {
    this.setState({ showSourcesForWidget: widget });
  }

  onShowDataQualityForWidget(widget) {
    this.setState({ showDataQualityForWidget: widget });
  }

  render() {
    const { isLoading } = this.props;
    // console.log("Home.WidgetPage.state", this.state);
    // console.log("Home.WidgetPage.props", this.props);

    if (isLoading) {
      return <Loader fullScreen />;
    }

    if (isEmpty(this.state.regions) || isEmpty(this.state.pages)) {
      return <div />;
    }

    let selectedBuilding = null;
    if (this.state.selectedBuildingId && this.state.regions.length > 0) {
      // Find the region containing the selected building
      const selectedRegion = this.state.regions.find(region =>
        region.children.some(child => child.id === this.state.selectedBuildingId)
      );

      // Find the selected building
      if (selectedRegion) {
        selectedBuilding = selectedRegion.children.find(child => child.id === this.state.selectedBuildingId);

        if (selectedBuilding.type === "region") {
          selectedBuilding = { ...selectedBuilding, name: selectedRegion.name };
        }
      }
    }
    else if (this.state.regions.length > 0) {
      // Find the region containing the selected building
      selectedBuilding = this.state.regions.find(region =>
        region.id === this.state.selectedBuildingId
      );
    }

    let selectedPage = null;
    let widgets = [];
    let customTagId = undefined;
    if (selectedBuilding) {
      // console.log("selectedBuidling", selectedBuilding);
     
      // Build widgets
      selectedPage = this.state.pages.find(page => page.id === this.state.selectedPageId);
      widgets = getWidgets(this.props.widgetProfile, this.state.selectedBuildingId, selectedPage, this.state.pages);

      if (selectedPage.id === "peopleCount") {
        // Find people count customTag for the selected building
        const locationPeopleCountCustomTags = get(this.props.widgetProfile, "locationPeopleCountCustomTags", []);
        customTagId = locationPeopleCountCustomTags.find(tag => tag.locationId === this.state.selectedBuildingId)?.customTagId;
        if (!customTagId) {
          customTagId = this.props.widgetProfile?.defaultPeopleCountCustomTagId;
        }
      }
    }

    let sourceView = null;
    if (this.state.showSourcesForWidget) {
      const widget = this.state.showSourcesForWidget;
      const widgetKey = `${this.state.selectedBuildingId}-${widget.wpaCategoryId ?? null}-${widget.customTagId ?? null}`; // use null instead of undefined
      const sourceData = get(this.props.widgetData, `[${widgetKey}].${widget.id}`, []);

      let sourceLocationIds = [];
      if (sourceData.count) {
        // Top/bottom locations
        sourceLocationIds = sourceData.results.map(result => result.locationId);
      }
      else if (sourceData.length > 0) {
        // Select last source data
        const lastSourceData = sourceData[sourceData.length - 1];

        // Distribution
        if (lastSourceData.distribution) {
          sourceLocationIds = lastSourceData.distribution.flatMap(distribution => distribution.locationIds).filter(x => x);
        }
        else if (lastSourceData.locationIds) {
          sourceLocationIds = lastSourceData.locationIds;
        }
      }

      sourceView = (
        <PopupContainer>
          <SourceView
            match={this.props.match}
            history={this.props.history}
            locationIds={sourceLocationIds}
            onClose={() => this.setState({ showSourcesForWidget: null })}
          />
        </PopupContainer>
      );
    }
    else if (this.state.showDataQualityForWidget) {
      const widget = this.state.showDataQualityForWidget;
      const widgetKey = `${this.state.selectedBuildingId}-${widget.wpaCategoryId ?? null}-${widget.customTagId ?? null}`; // use null instead of undefined
      const sourceData = get(this.props.widgetData, `[${widgetKey}].${widget.id}`, []);

      let sourceLocationIds = [];
      if (sourceData.count) {
        // Top/bottom locations
        sourceLocationIds = sourceData.results.map(result => result.locationId);
      }
      else if (sourceData.length > 0) {
        // Select last source data
        const lastSourceData = sourceData[sourceData.length - 1];

        // Distribution
        if (lastSourceData.distribution) {
          sourceLocationIds = lastSourceData.distribution.flatMap(distribution => distribution.locations).filter(x => x);
        }
      }
      
      sourceView = (
        <PopupContainer>
          <SmallUptimeView
            match={this.props.match}
            history={this.props.history}
            widget={widget}
            topLocation={selectedBuilding}
            onClose={() => this.setState({ showDataQualityForWidget: null })}
          />
        </PopupContainer>
      );
    }

    return (
      <OuterContainer>
        <LayoutContainer>
          <SideBarContainer>
            <HomeSideBar
              regions={this.state.regions}
              selectedLocationId={this.state.selectedBuildingId}
              onLocationClick={this.onBuildingClick}
              onSearchChange={this.onSearchChange}
              onSearchClick={this.onSearchClick}
            />
          </SideBarContainer>
          <InnerContainer $showSideBar>
            <HomeHeader
              pages={this.state.pages}
              selectedPageId={selectedPage ? selectedPage.id : null}
              selectedLocation={selectedBuilding}
              favoriteLocationId={this.state.favBuildingId}
              onSelectedPageChanged={this.onSelectedPageChanged}
              onToggleFavorite={this.onToggleFavorite}
              onSearchChange={this.onSearchChange}
              onSearchClick={this.onSearchClick}
            />
            <WidgetGrid
              page={selectedPage}
              building={selectedBuilding}
              customTagId={customTagId}
              widgets={widgets}
              history={this.props.history}
              match={this.props.match}
              onShowSourcesForWidget={this.onShowSourcesForWidget}
              onShowDataQualityForWidget={this.onShowDataQualityForWidget}
            />
          </InnerContainer>
        </LayoutContainer>
        { sourceView }
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.auth.selectedCompany,
    locationHierarchy: state.locationQuery.hierarchy,
    flatHierarchy: state.locationQuery.flatHierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    customTags: state.customTags.list,
    isLoading: state.loading.widgets,
    widgetData: state.widgets.data,
    widgetLoading: state.widgets.loading,
    wpaCategories: state.report.wpaCategories,
    widgetProfile: state.widgets.profile,
    dashboardData: state.dashboards.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getFullyUtilizedMinutes: widgetActions.getFullyUtilizedMinutes,
    getNotUtilizedMinutes: widgetActions.getNotUtilizedMinutes,
    getNeverUtilized: widgetActions.getNeverUtilized,
    getAtCapacityMinutes: widgetActions.getAtCapacityMinutes,
    getUtilizationIntraday: widgetActions.getUtilizationIntraday,
    getUtilizationDay: widgetActions.getUtilizationDay,
    getUtilizationWeekday: widgetActions.getUtilizationWeekday,
    getUtilizationMonth: widgetActions.getUtilizationMonth,
    getUtilizationMonthlyDistribution: widgetActions.getUtilizationMonthlyDistribution,
    getUtilizationTopLocations: widgetActions.getUtilizationTopLocations,
    getUtilizationBottomLocations: widgetActions.getUtilizationBottomLocations,
    getPeopleCountIntraday: widgetActions.getPeopleCountIntraday,
    getPeopleCountDay: widgetActions.getPeopleCountDay,
    getPeopleCountWeekday: widgetActions.getPeopleCountWeekday,
    getPeopleCountMonth: widgetActions.getPeopleCountMonth,
    getWpaCategories: reportActions.getWpaCategories,
    getWidgetProfile: widgetActions.getWidgetProfile,
    setWidgetSearch: widgetActions.setWidgetSearch
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsPage);

const OuterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 115px);
  width: 100%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }

  p, ol {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

const LayoutContainer = styled.div`
  display: inline-flex;
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
`;

const SideBarContainer = styled.div`
  position: relative;
  min-width: 300px;
  max-width: 300px;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`;

const InnerContainer = styled.div`
  position: relative;
  // width: ${props => props.$showSideBar ? "calc(100% - 300px)" : "100%"};
  flex: 1;
  max-height: 100%;
  height: 100%;
  overflow: auto;

  padding-left: ${props => props.$mobile ? "20px" : "20px"};
  padding-right: ${props => props.$mobile ? "20px" : "20px"}; // 95 is from the menu bar and 40 is from the padding
  padding-top: 30px; // 40px
  padding-bottom: 40px;
  box-sizing: border-box;
  text-align: left;
`;

const PopupContainer = styled.div`
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 80px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  overflow-y: scroll;
`;