import { get, isEmpty } from "lodash";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";
import { populateAncestors, flattenHierarchy } from "../locationHelpers";
import { sortSensorTypes, sensorTypeIgnoreList } from "../dashboardHelpers";

const initialState = {
  data: {
    count: 0,
    locations: [],
    limit: 20
  },
  hierarchy: [],
  breadcrumbs: {},
  uploadResponse: {},
  uploadErrors: [],
  quickSearchLocations: [],
  quickSearchText: "",
  correlationIds: {},

  // Weekdays data for locations
  locationsOccupancyWeekdays: {},
  locationsMaxPeopleWeekdays: {}
};

export default function locationsReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_LOCATION) {
        // Clear locations list when changing location viewing
        return { ...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_ROOT_LOCATION) {
        return { ...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_LOCATIONS) {
        return { ...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {
        // Clear locations list when changing location viewing
        return { ...state, data: initialState.data };
      }

      if (action.fetchType === types.UPLOAD_LOCATION_CSV) {
        return { ...state, uploadResponse: {}, uploadErrors: [] };
      }

      if (action.fetchType === types.SEARCH_LOCATIONS) {
        let newState = { ...state };
        newState.correlationIds = { ...state.correlationIds, [types.SEARCH_LOCATIONS]: action.metadata.correlationId };
        return newState;
      }

      return state;
    }

    case types.RECV_DATA: {

      if (action.fetchType === types.GET_LOCATIONS) {
        return { ...state, data: action.payload };
      }

      if (action.fetchType === types.DELETE_LOCATIONS) {
        toast.success("Location(s) deleted");
        return state;
      }

      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {

        // Add breadcrumbs to locations
        let breadcrumbs = {};
        if (action.payload.length > 0) {
          populateAncestors(action.payload, [], breadcrumbs);
        }

        return { ...state, hierarchy: action.payload, breadcrumbs };
      }

      if (action.fetchType === types.ADD_LOCATION) {
        toast.success("Location(s) added");
        return state;
      }

      if (action.fetchType === types.MOVE_LOCATIONS) {
        toast.success("Location(s) moved");
        return state;
      }

      if (action.fetchType === types.MAP_FEATURE_TO_LOCATION) {
        toast.success("Location mapped");
        return state;
      }

      if (action.fetchType === types.REMOVE_FEATURES_FROM_LOCATIONS) {
        toast.success("Location features removed");
        return state;
      }

      if (action.fetchType === types.UPLOAD_LOCATION_CSV) {
        if (isEmpty(action.payload.csvHash)) {
          return { ...state, uploadResponse: {}, uploadErrors: [] };
        }

        return { ...state, uploadResponse: action.payload, uploadErrors: [] };
      }

      if (action.fetchType === types.SEARCH_LOCATIONS) {
        if (action.metadata.correlationId !== state.correlationIds[types.SEARCH_LOCATIONS]) {
          return state;
        }

        return { ...state, quickSearchLocations: get(action, "payload.locations", []) };
      }


      if (action.fetchType === types.GET_LOCATIONS_OCCUPANCY_WEEKDAYS) {

        // Traverse all locations and add the weekdays data to the state
        const locationsOccupancyWeekdays = { ...state.locationsOccupancyWeekdays };
        action.payload.forEach((data) => {
          const locationId = data.locationId;
          locationsOccupancyWeekdays[locationId] = { ...locationsOccupancyWeekdays[locationId], weekdays: data.weekdays };
        });

        return { ...state, locationsOccupancyWeekdays };
      }

      if (action.fetchType === types.GET_LOCATIONS_MAX_PEOPLE_WEEKDAYS) {

        // Traverse all locations and add the weekdays data to the state
        const locationsMaxPeopleWeekdays = { ...state.locationsMaxPeopleWeekdays };
        action.payload.forEach((data) => {
          const locationId = data.locationId;
          locationsMaxPeopleWeekdays[locationId] = { ...locationsMaxPeopleWeekdays[locationId], weekdays: data.weekdays };
        });

        return { ...state, locationsMaxPeopleWeekdays };
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_LOCATION) {
        return state;
      }

      if (action.fetchType === types.GET_ROOT_LOCATION) {
        toast.error(`${statusCode}: Could not get root location`);
        return state;
      }

      if (action.fetchType === types.GET_LOCATIONS) {
        toast.error(`${statusCode}: Could not get locations`);
        return state;
      }

      if (action.fetchType === types.DELETE_LOCATIONS) {
        toast.error(`${statusCode}: Could not delete location(s)`);
        return state;
      }

      if (action.fetchType === types.ADD_LOCATION) {
        toast.error(`${statusCode}: Could not add location`);
        return state;
      }

      if (action.fetchType === types.MOVE_LOCATIONS) {
        toast.error(`${statusCode}: Could not move location(s)`);
        return state;
      }

      if (action.fetchType === types.MAP_FEATURE_TO_LOCATION) {
        toast.error(`${statusCode}: Could not connect location`);
        return state;
      }

      if (action.fetchType === types.REMOVE_FEATURES_FROM_LOCATIONS) {
        toast.error(`${statusCode}: Could not remove feature(s)`);
        return state;
      }

      if (action.fetchType === types.UPLOAD_LOCATION_CSV) {
        const errors = get(action, "payload.response.data.errors", []);
        toast.error(`${statusCode}: Could not upload`);
        return { ...state, uploadResponse: {}, uploadErrors: errors };
      }

      if (action.fetchType === types.GET_LOCATION_CSV) {
        toast.error(`${statusCode}: Could not download csv`);
        return state;
      }

      if (action.fetchType === types.GET_RESOURCE_CSV) {
        toast.error(`${statusCode}: Could not download csv`);
        return state;
      }

      if (action.fetchType === types.SEARCH_LOCATIONS) {
        return { ...state, quickSearchLocations: [] };
      }

      return state;
    }

    case types.CLEAR_DATA: {
      if (action.fetchType === types.UPLOAD_LOCATION_CSV) {
        return { ...state, uploadResponse: {}, uploadErrors: [] };
      }

      return initialState;
    }

    case types.UPDATE_QUICK_SEARCH_TEXT: {
      return { ...state, quickSearchText: action.payload };
    }

    default: {
      return state;
    }
  }
}
